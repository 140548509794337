<template>
  <ion-page> 404  </ion-page>
</template>

<script>
import { IonPage } from "@ionic/vue";
import { useRouter } from "vue-router";
export default {
  name: "404",
  components: IonPage,
  setup() {
    const router = useRouter();

    function back() {
      router.back();
    }
    return { back };
  },
};
</script>

<style scoped></style>
